.cookies-notification {
  @include block-white();
  @include transition();
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: $offset-3;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  align-items: center;
  color: $color-white;
  background: $color-black;

  @include mobile {

    flex-direction: column;
  }


  &__title {
    @include font(bold);
  }

  &__close {
    width: 2.4rem;
    height: 2.4rem;

    svg {
      @include transition();
      width: 100%;
      height: 100%;
      stroke: $color-black;
    }

    &:hover {
      svg {
        stroke: rgba(51,51,51, .5);
      }
    }
  }

  &__btn {
    margin-left: $offset-2;

    @include mobile {
      width: 100%;
      max-width: 100%;
      margin-top: $offset-3;
      margin-left: 0;
    }

  }

  &.show {
    display: flex;
  }
}
