.grid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: $grid-gap;

	&__left {
    grid-column: 5 span;

    @include small-tablet {
      grid-column: 12 span;
    }
	}
	&__right {
    grid-column: 7/6 span;

    @include small-tablet {
      grid-column: 12 span;
    }
	}
}
