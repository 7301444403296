.our-production {

}

.catalog {

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $grid-gap;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    @include block-white();
  }

}
.catalog-card {


  &:hover {
    .catalog-card__info {
      background: $color-accent;
      color: $color-white;
    }
    .catalog-card__img {
      img {
        transform: scale(1.1);
      }
    }
    .catalog-card__arrow {
      transform: translateX(0);
      svg {
        stroke: $color-white;
      }
    }
  }

  &__info {
    @include transition();
    background: #fff;
    padding: $offset-3;
    height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
      grid-template-columns: 1fr;
      height: auto;
    }

  }
  &__img {
    min-height: 20rem;
    position: relative;
    overflow: hidden;

    img {
      @include transition();
  		position: absolute;
  		left: 0;
  		top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
  &__title {
    margin-bottom: $offset-4;
  }
  &__bottom {
    display: flex;
  }
  &__arrow {
    @include transition();
    width: 2.4rem;
    height: 2.4rem;
    margin-left: auto;
    transform: translateX(-1rem);

    svg {
      stroke: $color-black;
    }
  }
  &__price {

  }
}
