/*
Вызов, например для h1:
h1 {
  @include desktop {
    описание стилей для селектора
  }
}
*/

/*
$grid-breakpoints: (
  xs: < 576px,
  sm: >= 576px,
  md: >= 768px,
  lg: >= 992px,
  xl: >= 1200px,
  xxl: >= 1400px
);
*/


@mixin desktop {
  @media (min-width: (1400px)) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: (1199px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (991px)) {
    @content;
  }
}

@mixin small-tablet {
  @media (max-width: (767px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (575px)) {
    @content;
  }
}


/*

@media (min-width: 1200px) {

}

@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 768px) and (max-width: 991px) {

}

@media (min-width: 480px) and (max-width: 767px) {

}

@media (min-width: 320px) and (max-width: 479px) {

}

@media (max-width: 320px) {

}

*/
