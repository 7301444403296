// <div class="debug debug-4fr">
//   <div class="container">
//     <div class="debug-in">
//       <div class="span"></div>
//       <div class="span"></div>
//       <div class="span"></div>
//       <div class="span"></div>
//     </div>
//   </div>
// </div>

// <div class="debug debug-3fr">
//   <div class="container">
//     <div class="debug-in">
//       <div class="span"></div>
//       <div class="span"></div>
//       <div class="span"></div>
//     </div>
//   </div>
// </div>

.debug {
  &-4fr {
    display: none;
  }

  &-3fr {
    display: none;
  }
}

.debug {
	position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
	opacity: .25;

  &-4fr {

    .debug-in {
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 4rem;
    }
  }

  &-3fr {

    .debug-in {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 4rem;
    }
  }

	&-in {
		height: 100%;
		display: grid;


	.span {
		min-height: 100vh;
		box-shadow: -1px 0px 0px 0px red, 1px 0px 0px 0px red;

			&:first-child {
		    box-shadow: -1px 0px 0px 0px red, 1px 0px 0px 0px red;
		  }

		}
	}
}
