.hero {
  position: relative;
  padding: $offset-1 * 2  0;
  margin: 0 $offset-3;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(11,35,77,.85) 0%,  rgba(68,107,176,.35) 100%);
    z-index: -1;
  }

  &__content {
    color: $color-white;
    text-align: center;
  }

  &__subtitle {
      margin-bottom: $offset-3;
  }
  &__breadcrumbs {
    justify-content: center;
    margin-bottom: $offset-3;
  }

  &__img {
    position: absolute;
		left: 0;
		top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    z-index: -1;
  }

  &__btn {
    margin-top: $offset-2;
  }

  &--inner {
    padding: $offset-1 * 1.5  0;
  }
}
