.acc {
  position: relative;
  overflow: hidden;

  &__btn {

  }
  &__ico {
    svg {
      transition: all .3s ease;
    }
  }
  &--active {
    color: $color-accent;

    .acc__ico {
      svg {
        transform: rotate(45deg);
        stroke: black;
      }
    }
  }

  &__content {
     overflow: hidden;
     display: none;
  }
}
