*,
*::before,
*::after {
	box-sizing: border-box;
}

@mixin block-white() {
  background: #fff;
  box-shadow: 0 2.5rem 5rem rgba(184,184,184, .035);
}

html, body {
 min-height: 100%;
 height: 100%;
}

html {
  font-size: 10px;
}

body {
	@include font();
  font-size: 1.6rem;
  line-height: 1.5;
  position: relative;
	color: $color-black;
	background: $color-white;
	//background: #F1F2F5;
}
svg {
  display: block;
  width: 100%;
  height: 100%;
}
.page {
  position: relative;
  display: flex;
  flex-direction: column;
	height: 100%;

	&-header {
		flex: 0 0 auto;
	}
	&-content {
		flex: 1 0 auto;
		overflow: hidden;
	}
	&-footer {
		flex: 0 0 auto;
	}
}

.btn-reset {
	padding: 0;
	margin: 0;
	border: 0;
  outline: none;
	background-color: transparent;
	border-radius: 0;
	cursor: pointer;
	appearance: none;
	display: inline-block;
}

.list-reset {
	list-style: none;
	margin: 0;
	padding: 0;
}

.input-reset {
	border: none;
	outline: none;
	width: 100%;
	overflow: auto;
	background: transparent;
}

.reset-margin {
	margin: 0;
}


// .container {
// 	max-width: 1240px;
// 	padding: 0 20px;
//   margin: 0 auto;
// }
.container {
  width: 100%;
	margin: 0 auto;
	padding: 0 20px;
	max-width: 1240px;

	// @include desktop {
	// 	max-width: 1240px;
	// }
	// @include small-desktop {
	// 	max-width: 1240px;
	// }
	// @include tablet {
	// 	//padding: 0 60px;
	// 	max-width: 960px;
	// }
	// @include small-tablet {
	// 	max-width: 720px;
	// }
	// @include mobile {
	// 	max-width: 540px;
	// }
}

// @media (min-width: 576px) {
//   .container {
//     max-width: 540px;
//   }
// }
// @media (min-width: 768px) {
//   .container {
//     max-width: 720px;
//   }
// }
// @media (min-width: 992px) {
//   .container {
//     max-width: 960px;
//   }
// }
// @media (min-width: 1200px) {
//   .container {
//     max-width: 1140px;
//   }
// }
// @media (min-width: 1400px) {
//   .container {
//     max-width: 1320px;
//   }
// }

.img-wrap {
	position: relative;
  overflow: hidden;
	height: 100%;

	@include small-tablet {
		min-height: 32rem;
	}

  img {
		position: absolute;
		left: 0;
		top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

img {
	max-width: 100%;
  border: 0px;
  border-style:none;
  outline: 0;
}

a {
	@include transition();
	color: $color-black;
	text-decoration: none;

	&.link {
		color: $color-accent;

		&:hover {
			color: lighten($color-accent, 20%);
		}
	}
	&.link--white {
		color: $color-white;
	}
}

p {
  line-height: 1.5;
  margin: 0 0 $offset-3 0;
}

.txt {
  &1 {
		font-size: 2.8rem;
		line-height: 1.3;
  }
  &2 {
    font-size: 2rem;
    line-height: 1.3;
  }
	&3 {
    font-size: 1.6rem;
    text-transform: uppercase;
  }
	&4 {
    font-size: 1.4rem;
  }

  &-light {
    color: $color-gray;
  }
  &-accent {
    color: $color-accent;
  }
}

h1, h2, h3, h3, h4, h5, h6 {
	font-weight: 400;
}

h1,.h1 {
	margin: 0;
	font-size: 6rem;
	line-height: 1.2;
	text-transform: uppercase;

	@include mobile {
		font-size: 5rem;
		line-height: 1.1;
	}

}

h2,.h2 {
	margin: 0;
	font-size: 5rem;
	line-height: 1.1;
	text-transform: uppercase;
}

h3,.h3 {
	margin: 0;
	font-size: 3.2rem;
	line-height: 1.25;
	text-transform: uppercase;
	@include font(bold);
}
h4,.h4 {
	@include font(bold);
	margin: 0;
	font-size: 2.4rem;
	line-height: 1.25;
	text-transform: uppercase;
}
h5,.h5 {
	margin: 0;
	font-size: 1.6rem;
	text-transform: uppercase;
}


.title {
	text-transform: uppercase;
	@include font(bold);
}


.s {
	padding: $offset-1 0;
	position: relative;
	// border: 3px solid red;
	//
	// &:nth-child(odd) {
	// 	border: 3px solid green;
	// }

	&--fill {
		padding: $offset-1 0;
		color: $color-white;
		background: $color-accent;
		margin: $offset-1 0;
	}
	&__inner {
		margin-top: $offset-2;
	}

	&__header {
		text-align: center;
		margin-bottom: $offset-1;
	}
}

.list-accent {
	list-style-type: none;
	padding: 0;

	&__item {
		font-size: 2rem;
		position: relative;
		margin-left: $offset-2;

		&:not(:last-child) {
			margin-bottom: $offset-3;
		}
		&:before {
			content: "";
			position: absolute;
			left: -$offset-2;
			top: .8rem;
			width: 1.2rem;
			height: 1.2rem;
			border: 3px solid $color-accent;
			border-radius: 50%;
		}
	}
}
