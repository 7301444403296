.portfolio {
  &__inner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    @include small-tablet {
  		grid-template-columns: 1fr;
  	}
  }
  &__consultation {
    background: $color-accent;
    height: calc(100% - $offset-3 - 6rem);
    grid-column: 4 span;
    padding: $offset-2;
    color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include small-tablet {
      order: 2;
      margin-top: $offset-1;
    }
  }

  &__gallery {
    overflow: hidden;
    grid-column: 8 span;


    @include small-tablet {
      order: 1;
  	  grid-column: 1 span;
  	}

    .swiper {
      &-container {

      }
      &-wrapper {

      }
      &-slide {
        height: 48rem;

        @include small-tablet {
      	  height: 40rem;
      	}
        @include mobile {
      	  height: 32rem;
      	}

      }
      &-nav {
        justify-content: flex-start;
      }
      &-pagination {
        margin-left: 3rem;
      }
    }

  }
}
