@include font-face("Manrope", "/assets/fonts/manrope/Manrope-Regular", 400);
@include font-face("Manrope", "/assets/fonts/manrope/Manrope-ExtraBold", 800);


@mixin font ($style:'regular', $font-family: 'Manrope'){
  font-family: #{$font-family};

  @if $style == 'regular' {
    font-weight: 400;
  }@else if $style == 'bold' {
    font-weight: 800;
  }
}
