.menu {
  &__list {
    display: flex;
    flex-direction: row;
  }

  &__item {
    &:not(:last-child) {
      margin-right: $offset-3;
    }
  }

  &__item--active {
    pointer-events:none;
  }

  &__link {
    @include transition();
    @include font(bold);
    color: $color-black;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 1.4rem;

    &:hover {
      color: $color-gray;
    }
  }
}
