.statistics {

  &__item {
    color: $color-white;
    text-transform: uppercase;
    text-align: center;
  }

  &__num {
    font-size: 8rem;
    line-height: 1;
    margin-bottom: $offset-4;
  }

  &__descr {
    @include font(bold);
  }
}
