.input {
  height: 6rem;

  &__text {
    height: 100%;
  }
  &__label {

  }
}

/*** анимация моадльного окна ***/
.mfp{
  &-container {
    padding: 0;
  }

  &-bg {
    background: rgba(36,43,81, .7);
  }
  &-move {
    .mfp-with-anim {
      opacity: 0;
      transition: all 0.5s;
      transform: translateY(-5rem);
    }
    &.mfp-bg {
      opacity: 0;
      transition: all 0.5s;
    }

    &.mfp-ready {
      .mfp-with-anim {
        opacity: 1;
        transform: translateY(0);
      }
      &.mfp-bg {
        opacity: 1;
      }
    }

    &.mfp-removing {
      .mfp-with-anim {
        opacity: 0;
        transform: translateY(5rem);
      }
      &.mfp-bg {
        opacity: 0;
      }
    }
  }
}

/*** общие стили модального окна ***/
.modal {
  box-shadow: 0 5rem 10rem rgba(0, 0, 0, 0.25);
  margin: $offset-2 auto;
  position: relative;
  width: 60rem;
  padding: $offset-1 $offset-2 $offset-2;
  background: $color-white;

  @include small-tablet {
    width: 48rem;
  }
  @include mobile {
    width: 90%;
    padding: $offset-2 $offset-3 $offset-3;
  }


  &__close {
    position: absolute;
    width: 5rem;
    height: 5rem;
    top: .5rem;
    right: .5rem;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .3s ease;

    svg {
      @include transition();
      width: 2.4rem;
      height: 2.4rem;
      stroke: $color-black;
    }

    &:hover {
      svg {
        stroke: $color-accent;
      }
    }
  }
  &__inner {
    width: 100%;
    height: 100%;
  }

  &__body {

  }
  &__img {
    position: relative;
    height: 15rem;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__header {
    text-align: center;
  }
  &__form {
    margin-top: 6rem;
  }
}


.modal-form {
  &__field {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__input {
    position: relative;
    display: block;

    span {
      @include transition();
      position: absolute;
      top: 1rem;
      left: 0;
      opacity: 0;
      font-size: 1.4rem;
      color: $color-accent;
    }

  }
  &__input-text {
    @include transition();
    background-image: linear-gradient($color-accent, $color-accent), linear-gradient(#cccdce, #cccdce);
    background-size: 0 2px, 100% 2px;
    background-position: left bottom, left bottom;
    background-repeat: no-repeat;
    transition: background .3s ease-out;
    transition-duration: .3s;

    &:focus {
      background-size: 100% 2px, 100% 2px;
      transition-duration: .3s;

      + span {
        opacity: 1;
        top: -1rem;
      }
      &::placeholder{
        color: transparent;
      }
    }

    &::placeholder {
      @include transition();
      color: $color-gray;
    }
  }

  // &__input-label {
  //   @include transition();
  //   position: absolute;
  //   top: 1rem;
  //   left: 0;
  //   opacity: 0;
  //   font-size: 1.4rem;
  //   opacity: 1;
  // }

  &__btn {
    width: 100%;
  }

  &__wrapper {

  }
  &__bottom {
    margin-top: $offset-2;
  }
  &__privacy {
    width: 75%;
    font-size: 1.4rem;
    color: $color-gray;
    margin: $offset-3 auto;
    text-align: center;

    @include mobile {
      width: 100%;
    }
  }
}
