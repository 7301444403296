.about {
  &-production {
    padding: 0;
  }
  &__production-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include small-tablet {
      grid-template-columns: 1fr;
    }
  }
  &__production {
    //padding: $offset-1 0;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    img {
      position: absolute;
      object-fit: cover;
      object-position: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(11,35,77,.85) 0%,  rgba(68,107,176,.25) 100%);
      z-index: -1;
    }
  }

  &__production-content {
    padding: $offset-2;
  //  background: $color-accent;
    color: $color-white;
  }
  &__features {

  }
  &__info {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: $grid-gap;
    margin-top: $offset-1;

    @include small-tablet {
      grid-template-columns: 1fr;
    }
  }
}

.about-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: $grid-gap;

  &__item {
    &:not(:first-child) {
    //  margin-top: $offset-3;
    }
    &:not(:last-child) {
      //padding-bottom: $offset-3;
      border-bottom: 1px solid $color-gray--light;
    }
    //display: flex;
  }
  &__ico {
    height: 5.4rem;
    min-width: 10rem;

    img {
      height: 100%;
    }
  }
  &__content {
    margin-left: $offset-3;
  }
  &__title {
    @include font(bold);
    text-transform: uppercase;
    margin-bottom: $offset-4;
  }
  &__text {
    font-size: 1.4rem;
  }
}

.about-info {
  &__img {
    grid-column: 4 span;
  }
  &__content {
    grid-column: 7/6 span;
    padding: $offset-2 0;
  }
  &__statistics {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $grid-gap;
  }
}

.production {
  margin-top: $offset-1;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(11,35,77,.85) 0%,  rgba(68,107,176,.35) 100%);
    z-index: -1;
  }

  &__img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }

  &__inner {
    height: 100%;
    padding: $offset-1 * 2 0 $offset-1;
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $offset-1;
  }

  &__info {
    color: $color-white;
    margin-top: $offset-1;
    text-align: center;
  }

  &__statistics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $grid-gap;
  //  width: 80%;

    @include small-tablet {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
  	}
    .statistics__item {
      //padding: $offset-3;
      text-align: center;

      &:first-child {

      }

    }
  }

}


// &__info {
//   display: grid;
//   grid-template-columns: repeat(12, 1fr);
//   grid-gap: $grid-gap;
//   margin-top: $offset-1;
//
//   @include small-tablet {
//     grid-template-columns: 1fr;
//   }
// }
