.breadcrumbs {
  display: flex;

  &__item {
    @include transition();
    color: rgba(255,255,255,.5);
    transition: all .3s ease;
    font-size: 1.4rem;

    span {
      @include transition();
    }

    a {
      color: $color-white;
    }
  }

  &__separator {
    color: rgba(255,255,255,.5);
    margin: 0 $offset-4;
  }
}
