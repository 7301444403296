.product-card {

  &__inner {
    @include block-white();
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $grid-gap;
    margin-bottom: $offset-2;
    padding: $offset-2 $offset-3;

    @include tablet {
      grid-template-columns: 1fr;
    }
  }

  &__gallery {
    overflow: hidden;
  }


  &__title {
    @include font(bold);

    @include tablet {
      display: none;
    }
  }

  &__title--mobile {
    display: none;
    margin-bottom: $offset-2;

    @include tablet {
      display: block;
    }
  }

  &__price {
    margin: $offset-3 0;
    border-bottom: 1px solid  $color-gray--light;
  }

  &__price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid  $color-gray--light;
    padding: 1.5rem 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__descr {
    font-size: 1.4rem;
    margin-bottom: $offset-3;
    padding-bottom: $offset-3;
    border-bottom: 1px solid  $color-gray--light;
  }

  &__details {

  }
}

.product-details {
  font-size: 1.4rem;

  &__item {
    padding: 1rem 0;
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray--light;
    }
  }
  &__title {
    @include font(bold);
    width: 40%;
    font-size: 1.6rem;
  }
  &__text {
    width: 60%;
  }
}
.price {
  &__label {
    color: $color-gray;
  }
  &__current {
    font-size: 3.2rem;
  }
}

.adv-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -.5rem;

  >li {
    padding: .5rem 1.5rem;
    margin-left: .5rem;
  }

  &__item {
    @include font(bold);
    border-radius: 10rem;
    //background: $color-accent--alt;
    border: 2px solid $color-accent--alt;
    color: $color-accent--alt;
    font-size: 1.2rem;
    margin-top: $offset-4;
    text-transform: uppercase;
    }
  }


.product-card {
  .swiper {
    &-container {

    }
    &-wrapper {

    }
    &-slide {

    }
    &-nav {

    }
    &-pagination {
      margin-left: 3rem;
    }
  }
}



.product-card {
  &__acc {

  }
}

.product-card-acc {
  &__item {
    //background: #fff;
  }
  &__header {
    @include font(bold);
    display:  flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    width: 100%;
    padding: $offset-3 0;
    cursor: pointer;

    &:hover {
      color: $color-accent;
    }
  }
  &__title {
    font-size: 2.4rem;
  }
  &__ico {
    width: 2.4rem;
    height: 2.4rem;

    svg {
      width: 100%;
      height: 100%;
      stroke: $color-black;
    }
  }
  &__content {
    //padding: 0 $offset-3;
  }
}
