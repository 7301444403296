//Цвета
$color-white: #F7F5F4;
$color-accent: #446BB0;
$color-accent--alt: #7DB648;
//$color-accent: #2B3A70;
$color-black: #2C2C2C;
$color-gray: #B8B8B8;
$color-gray--light: #DAD8D8;
// отступы
$grid-gap: 4rem;
$offset-0: 16rem;
$offset-1: 8rem;
$offset-2: 4rem;
$offset-3: 2rem;
$offset-4: 1rem;
$offset-5: .5rem;
