.features {
  padding-bottom: 0;

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $grid-gap;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: 1fr;
    }

  }

  &__item {
    position: relative;

    @include tablet {
      padding-bottom: $offset-3;
      border-bottom: 1px solid $color-gray--light;
    }

    @include mobile {
      text-align: center;
      border-bottom: 0;
      
      &:not(:last-child){
        border-bottom: 1px solid $color-gray--light;
      }
    }
  }

}

.features-item {
  &__ico {
    margin-bottom: $offset-3;
    height: 5.4rem;

    img {
      height: 100%;
    }
  }
  &__title {
    @include font(bold);
    text-transform: uppercase;
    margin-bottom: $offset-4;
  }
  &__text {
    font-size: 1.4rem;
  }
}
