.footer {
  &__main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $grid-gap;
    margin-bottom: $offset-2;

    @include mobile {
      grid-template-columns: 1fr;
    }

  }
  &__bottom {
    border-top: 1px solid $color-gray--light;
    padding: $offset-3 0;
    color: $color-gray;
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
    }
  }
  &__info {

  }

  &__logo {
    width: 25rem;
    opacity: .25;
  }
  &__copyright {
    color: $color-gray;
    display: flex;
  }
  &__copyright-company {
    margin-right: $offset-3;
  }
  &__copyright-link {
    color: $color-gray !important;
  }

}

.footer-contacts {
  &__item {
    &:not(:last-child) {
      margin-bottom: $offset-3;
    }
  }
  &__label {
    @include font(bold);
    text-transform: uppercase;
  }
  &__value {
    font-size: 2.4rem;
    a {
      white-space: nowrap;
    }
  }
}
