.path {

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $grid-gap;
    counter-reset: num 0;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    @include transition();
    @include block-white();
    text-align: center;
    padding: 3rem;
    min-height: 24rem;
    position: relative;
    overflow: hidden;
    z-index: 0;
    background: #fff;

    &:hover {
        &:after {
          line-height: .5;
          color: lighten($color-accent, 25%);
        }
    }

    &:after {
      @include font(bold);
      counter-increment: num;
      content: counter(num) "";
      font-size: 30rem;
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: .25;
      transition: all .5s ease;
      width: 100%;
      display: block;
      z-index: -1;
      color: lighten($color-gray, 20%);
    }

  }

}

.path-item {
  &__title {
    @include font(bold);
    text-transform: uppercase;
    margin-bottom: $offset-4;
  }
  &__text {
    font-size: 1.4rem;
  }
}
