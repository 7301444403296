
@mixin transition ($el:'all', $duration: '.3s', $timing: 'ease'){
  transition: #{$el} #{$duration} #{$timing};

  // @if $style == 'regular' {
  //   font-weight: 400;
  // }@else if $style == 'medium' {
  //   font-weight: 500;
  // }@else if $style == 'bold' {
  //   font-weight: 600;
  // }
}
