.colors {
  &__grid-left {
    @include small-tablet {
      order: 2;
    }
  }
  &__grid-right {
    @include small-tablet {
      order: 1;
    }
  }
}
