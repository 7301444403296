.btn-group {

}

.btn {
  @include transition();
  @include font(bold);
  border-radius: .3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  padding: 0 3rem;
  width: auto;
  white-space: nowrap;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: .7px;

  &--primary {
    background: $color-accent;
    color: $color-white;

    &:hover {
      //background: lighten($color-accent, 10%);
      background: $color-accent--alt;
    }
  }

  &--light {
    background: $color-white;
    color: $color-accent;

    &:hover {
      background: $color-black;
      color: $color-white;
    }
  }

  &--secondary {
    background: transparent;
    color: $color-accent;
    border: 2px solid $color-accent;

    &:hover {
      background: $color-accent;
      color: $color-white;
    }

  }
}
