.header {
  height: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__right {
    display: flex;
    align-items: center;
  }
  &__menu {
    margin-right: $offset-2;

    @include small-tablet {
      display: none;
    }

  }

  &__logo {
    width: 18rem;
    display: block;
  }

  &__contacts {
    display: flex;
    align-items: center;
  }

  &__phone {
    @include font(bold);
    font-size: 2rem;
    white-space: nowrap;
  }

  &__callback {
    margin-left: $offset-2;
    
    @include mobile {
      display: none;
    }
  }

  &__btn {
    height: 4rem;
  }
}
