.content {
  z-index: 0;
  flex: none;
  width: 100%;
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    @include font(bold);
    font-size: 5rem;
  	line-height: 1.1;
    text-transform: uppercase;
    margin: 0 0 3rem;
  }

  h3 {
    @include font(bold);
    font-size: 3.2rem;
  	line-height: 1.25;
  	text-transform: uppercase;
    margin: 0 0 3rem;
  }

  h4 {
    @include font(bold);
    font-size: 2.4rem;
  	line-height: 1.25;
  	text-transform: uppercase;
    margin: 0 0 2.8rem;
  }

  h5 {
    @include font(bold);
    font-size: 1.6rem;
  	text-transform: uppercase;
    margin: 0 0 2rem;
  }

  h6 {
    font-size: 1.8rem;
    line-height: 1.3;
    margin: 0 0 1.8rem;
  }

  p {
    line-height: 1.5;
    margin: 0 0 1.8rem;

    &:last-child {
      margin-bottom: 0;
    }

    b {
      @include font(bold);
    }
  }

  ul {
    margin: 0 0 1.8rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
