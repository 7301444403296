.swiper {
  &-default {

  }
  &-container {
    width: 100%;
    position: relative;
    z-index: 0;
  }
  &-slide {
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &-nav {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: $offset-3;

    &__arrow {
      @include transition();
      width: 6rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.--prev {
        transform: rotate(180deg);
      }

      svg {
        @include transition();
        stroke: $color-black;
        width: 2.4rem;
        height: 2.4rem;
      }

      &:hover {
        background: $color-accent;

        svg {
          stroke: $color-white;
        }
      }
    }

  }

  &-control {
    display: flex;
    flex-direction: row;
  }

  &-pagination {
    text-align: center;
    position: relative;
    width: auto;
    bottom: 0;
  }
}
